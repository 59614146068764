// Packages
import React, { createContext, useState, Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
// Utils
import ReactSession from "./utils/reactSession";
import PrivateRoute from "./utils/routing/privateRoute/privateRoute";
import CustomRouter from "./utils/routing/customRouter/customRouter";
import history from "./utils/routing/customRouter/history";
import { useMediaQuery } from "react-responsive";
import ScrollToTop from "./utils/scrollToTop/scrollToTop";
import "./utils/i18n";
import "antd/dist/antd.min.css";
import "@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css";
import 'mapbox-gl/dist/mapbox-gl.css'
// Components
import Layout from "./assemblies/layout/layout";
import MainLoading from "./components/loadings/mainLoading/mainLoading";
const Contracts = lazy(() => import("./pages/contracts/contracts"));
const NewContract = lazy(() => import("./pages/contracts/newContract/newContract"));
const EditContract = lazy(() => import("./pages/contracts/editContract/editContract"));
const ContractDetails = lazy(() => import("./pages/contracts/contractDetails/contractDetails"));
const DataExport = lazy(() => import("./pages/dataExport/dataExport"));
const ForgotPassword = lazy(() => import("./pages/forgotPassword/forgotPassword"));
const Notifications = lazy(() =>import("./assemblies/headers/websiteHeader/right/notifications/moreNotifications/moreNotifications"));
const Device = lazy(() => import("./pages/iotControl/device/device"));
const EditDevice = lazy(() => import("./pages/iotControl/device/editDevice/editDevice"));
const NewDevice = lazy(() => import("./pages/iotControl/device/newDevice/newDevice"));
const RawDataDevice = lazy(() => import("./pages/iotControl/device/rawData/rawData"));
const RawDataTable = lazy(() => import("./pages/iotControl/device/rawDataTable/rawDataTable"));
const DeviceGroup = lazy(() => import("./pages/iotControl/deviceGroup/deviceGroup"));
const EditDeviceGroup = lazy(() => import("./pages/iotControl/deviceGroup/editDeviceGroup/editDeviceGroup"));
const NewDeviceGroup = lazy(() => import("./pages/iotControl/deviceGroup/newDeviceGroup/newDeviceGroup"));
const DeviceImport = lazy(() => import("./pages/iotControl/deviceImport/deviceImport"));
const DeviceModel = lazy(() => import("./pages/iotControl/deviceModel/deviceModel"));
const EditDeviceModel = lazy(() => import("./pages/iotControl/deviceModel/editDeviceModel/editDeviceModel"));
const NewDeviceModel = lazy(() => import("./pages/iotControl/deviceModel/newDeviceModel/newDeviceModel"));
const DeviceType = lazy(() => import("./pages/iotControl/deviceType/deviceType"));
const EditDeviceType = lazy(() => import("./pages/iotControl/deviceType/editDeviceType/editDeviceType"));
const NewDeviceType = lazy(() => import("./pages/iotControl/deviceType/newDeviceType/newDeviceType"));
const IotEditFirmware = lazy(() => import("./pages/iotControl/firmware/editFirmware/editFirmware"));
const IotFirmware = lazy(() => import("./pages/iotControl/firmware/firmware"));
const IotNewFirmware = lazy(() => import("./pages/iotControl/firmware/newFirmware/newFirmware"));
const IotSettingsEdit = lazy(() => import("./pages/iotControl/iotSettings/editSettings/editSettings"));
const IotSettings = lazy(() => import("./pages/iotControl/iotSettings/iotSettings"));
const EditPlace = lazy(() => import("./pages/iotControl/location/editLocation/editLocation"));
const LocationDetailsPage = lazy(() => import("./pages/iotControl/location/locationsDetails/locationsDetails"));
const NewPlace = lazy(() => import("./pages/iotControl/location/newLocation/newLocation"));
const Place = lazy(() => import("./pages/iotControl/location/location"));
const EditStructure = lazy(() => import("./pages/iotControl/structure/editStructure/editStructure"));
const NewStructure = lazy(() => import("./pages/iotControl/structure/newStructure/newStructure"));
const Structure = lazy(() => import("./pages/iotControl/structure/structure"));
const LandingPage = lazy(() => import("./pages/landingPage/landingPage"));
const LoginPage = lazy(() => import("./pages/login/loginPage"));
const HomePage = lazy(() => import("./pages/homePage/homePage"));
const NoAccess = lazy(() => import("./pages/noAccess/noAccess"));
const RawData = lazy(() => import("./pages/rawData/rawData"));
const PlaceDetail = lazy(() => import("./pages/placeDetail/placeDetail"));
const SmartWaste = lazy(() => import("./pages/smartWaste/smartWaste"));
const UsersEditEntity = lazy(() => import("./pages/usersManagement/entities/editEntity/editEntity"));
const UsersEntity = lazy(() => import("./pages/usersManagement/entities/entities"));
const UsersEntityDetails = lazy(() => import("./pages/usersManagement/entities/entityDetails/entityDetails"));
const UsersNewEntity = lazy(() => import("./pages/usersManagement/entities/newEntity/newEntity"));
const UsersManagementInviteUsers = lazy(() => import("./pages/usersManagement/users/inviteUsers/inviteUsers"));
const UsersManagementUsers = lazy(() => import("./pages/usersManagement/users/users"));
const UsersManagementUsersDetails = lazy(() => import("./pages/usersManagement/users/usersDetails/usersDetails.js"));
const UsersManagementEmployees = lazy(() => import("./pages/usersManagement/employees/employees"));
const UsersManagementEmployeesDetails = lazy(() => import("./pages/usersManagement/employees/employeesDetails/employeesDetails.js"));
const UsersManagementInviteEmployee = lazy(() => import("./pages/usersManagement/employees/inviteEmployee/inviteEmployee.js"));
const Modal = lazy(() => import("./components/modals/reduxModal/modal"));
const Vehicles = lazy(() => import("./pages/giro/vehicles/vehicles"));
const VehicleDetails = lazy(() => import("./pages/giro/vehicles/vehicleDetails/vehicleDetails"));
const NewVehicle = lazy(() => import("./pages/giro/vehicles/newVehicle/newVehicle"));
const EditVehicle = lazy(() => import("./pages/giro/vehicles/editVehicle/editVehicle"));
const CollectionPoints = lazy(() => import("./pages/giro/collectionPoints/collectionPoints"));
const EditCollectionPoints = lazy(() => import("./pages/giro/collectionPoints/editCollectionPoint/editCollectionPoint"));
const Warehouses = lazy(() => import("./pages/giro/warehouses/warehouses"));
const WarehouseDetails = lazy(() => import("./pages/giro/warehouses/warehouseDetails/warehouseDetails"));
const NewWarehouse = lazy(() => import("./pages/giro/warehouses/newWarehouse/newWarehouse"));
const EditWarehouse = lazy(() => import("./pages/giro/warehouses/editWarehouse/editWarehouse"));
const Operations = lazy(() => import("./pages/giro/operations/operations"));
const Drivers = lazy(() => import("./pages/giro/drivers/drivers"));
const NewDriver = lazy(() => import("./pages/giro/drivers/newDriver/newDriver"));
const EditDriver = lazy(() => import("./pages/giro/drivers/editDriver/editDriver"));
const DriverDetail = lazy(() => import("./pages/giro/drivers/driverDetail/driverDetail"));
const RoutesPage = lazy(() => import("./pages/giro/routes/routes"));
const EditRoute = lazy(() => import("./pages/giro/routes/editRoute/editRoute"));
const NewRoute = lazy(() => import("./pages/giro/routes/newRoute/newRoute"));
const RouteDetails = lazy(() => import("./pages/giro/routes/routeDetails/routeDetails"));
const RoutesPlanned = lazy(() => import("./pages/giro/routesPlanned/routesPlanned"));
const NewRoutePlanned = lazy(() => import("./pages/giro/routesPlanned/newRoutePlanned/newRoutePlanned"));
const EditRoutePlanned = lazy(() => import("./pages/giro/routesPlanned/editRoutePlanned/editRoutePlanned"));
const RoutePlannedDetails = lazy(() => import("./pages/giro/routesPlanned/routePlannedDetails/routePlannedDetails"));
const MyCollectionsPage = lazy(() => import("./pages/giro/myCollections/myCollectionsPage"));
const NewMyCollection = lazy(() => import("./pages/giro/myCollections/newCollection/newCollectionPage"));
const Clients = lazy(() => import("./pages/giro/clients/clients"));
const ClientDetails = lazy(() => import("./pages/giro/clients/clientDetais/clientDetails.js"));
const NewClient = lazy(() => import("./pages/giro/clients/newClient/newClient"));
const EditClient = lazy(() => import("./pages/giro/clients/editClient/editClient"));
const Intervations = lazy(() => import("./pages/giro/interventions/interventions"));
const Waste = lazy(() => import("./pages/giro/waste/waste"));
const Egars = lazy(() => import("./pages/giro/egars/egars"));
const CollectionPointContract = lazy(() => import("./pages/giro/collectionPointsContracts/collectionPointsContracts"));
const NewCollectionPointContract = lazy(() =>import("./pages/giro/collectionPointsContracts/newCollectionPointContracts/newCollectionPointContracts"));
const CollectionContractDetail = lazy(() =>import("./pages/giro/collectionPointsContracts/collectionContractsDetail/collectionContractsDetail"));
const CollectionHistory = lazy(() => import("./pages/giro/collectionHistory/collectionHistory"));
const CollectionHistoryDetail = lazy(() =>import("./pages/giro/collectionHistory/collectionHistoryDetail/collectionHistoryDetail"));
const EditCollectionContract = lazy(() =>import("./pages/giro/collectionPointsContracts/editCollectionPointContract/editCollectionPointContracts"));
const EgarDetails = lazy(() => import("./pages/giro/egars/egarDetails/egarDetails"));
const Campaigns = lazy(() => import("./pages/campaigns/campaigns"));
const NewCampaigns = lazy(() => import("./pages/campaigns/newCampaign/newCampaign"));
const EditCampaign = lazy(() => import("./pages/campaigns/editCampaign/editCampaign"));
const AddCampaign = lazy(() => import("./pages/campaigns/addCampaign/addCampaign"));
const CampaignDetail = lazy(() => import("./pages/campaigns/campaignDetail/campaignDetail"));
const GiveawayDetail = lazy(() => import("./pages/campaigns/awardsAndGiveaways/awardsAndGiveaways"));
const Deposits = lazy(() => import("./pages/deposits/deposits"));
const DepositsDetail = lazy(() => import("./pages/deposits/depositsDetail/depositsDetail"));
const CollectionPlaces = lazy(() => import("./pages/giro/collectionPlaces/collectionPlaces"));
const NewCollectionPlaces = lazy(() => import("./pages/giro/collectionPlaces/newCollectionPlaces/newCollectionPlaces"));
const EditCollectionPlaces = lazy(() => import("./pages/giro/collectionPlaces/editCollectionPlaces/editCollectionPlaces"));
const CollectionPlaceDetail = lazy(() =>import("./pages/giro/collectionPlaces/collectionPlacesDetail/collectionPlaceDetail"));
const OilCollection = lazy(() =>import("./pages/giro/oilCollections/oilCollections"));
const Tg_AccountVerification = lazy(() =>import("./pages/cfToGo/accountVerification/accountVerification"));
const Tg_AccountVerificationDetails = lazy(() =>import("./pages/cfToGo/accountVerification/accountDetails/accountDetail"));
const Tg_Register = lazy(() =>import("./pages/cfToGo/register/register"));
const Tg_RegisterDetails = lazy(() =>import("./pages/cfToGo/register/registerDetails/registerDetails"));
const Tg_ClientsVerification = lazy(() =>import("./pages/cfToGo/clientsVerification/clientsVerification"));
const Tg_ClientDetails = lazy(() =>import("./pages/cfToGo/clientsVerification/clientDetails/clientDetails"));
const Tg_Delivery = lazy(() =>import("./pages/cfToGo/delivery/delivery.js"));
const Tg_DeliveryDetail = lazy(() =>import("./pages/cfToGo/delivery/deliveryDetails/deliveryDetails"));
const Tg_Collector = lazy(() =>import("./pages/cfToGo/collector/collector.js"));
const Tg_CollectorDetail = lazy(() =>import("./pages/cfToGo/collector/collectorDetails/collectorDetails.js"));
const Contacts = lazy(() =>import("./pages/contacts/contacts.js"));
const ContactsRegister = lazy(() =>import("./pages/contacts/contactClientDetails/contactsRegister/contactsRegister.js"));
const ContactClientDetails = lazy(() =>import("./pages/contacts/contactClientDetails/contactClientDetails.js"));
const Orders = lazy(() =>import("./pages/contacts/orders/orders.js"));
const Reports = lazy(() =>import("./pages/reports/reports.js"));
const WeeklyPrice = lazy(() =>import("./pages/cfToGo/weeklyPrice/weeklyPrice.js"));
const Workload = lazy(() =>import("./pages/contacts/workload/workload.js"));
const Params = lazy(() =>import("./pages/params/params.js"));
const DebugCollectors = lazy(() =>import("./pages/giro/myCollections/debugPage/debugPage.js"));

export const LoadingContext = createContext();
export const SidebarContext = createContext();

function App() {
	ReactSession.setStoreType("localStorage");
	const [isLoading, setIsLoading] = useState(false);
	const [isSidebarOpen, setIsSidebarOpen] = useState(useMediaQuery({ maxWidth: 540 }));

	return (
		<LoadingContext.Provider value={{ isLoading: isLoading, setIsLoading: setIsLoading }}>
			<SidebarContext.Provider value={{ isSidebarOpen: isSidebarOpen, setIsSidebarOpen: setIsSidebarOpen }}>
				<MainLoading loading={isLoading} />
				<Suspense fallback={<div style={{backgroundColor: "#e8eaed", position: "fixed", height: "100vh", width: "100vw"}}></div>}>
					<CustomRouter history={history}>
						<Modal />
						<ScrollToTop />
						<Routes>
							{/* ======= PUBLIC ROUTES ======= */}
							<Route path="/" element={<LandingPage />} />
							<Route path="/forgotPassword" element={<ForgotPassword />} />
							<Route path="/login" element={<LoginPage />} />
							{/* ======= CARBON FOOTE - PRIVATE ROUTES ======= */}
							<Route path="/cf" element={<Layout />}>
								<Route index element={<PrivateRoute element={<HomePage />} permission="IOT_Dashboard_Home_View" />} />
								{/* SMARTWASTE */}
								<Route path="smartwaste" element={<PrivateRoute element={<SmartWaste />} permission="IOT_Dashboard_SmartWaste_View" />} />
								<Route path="smartWaste/placeDetail/:placeId" element={<PrivateRoute element={<PlaceDetail />} permission="IOT_Dashboard_SmartWaste_Place_Detail" />} />
								{/* CAMPAIGNS */}
								<Route path="campaigns" element={<PrivateRoute element={<Campaigns />} permission="Campaigns_Side_Menu" />} />
								<Route path="campaigns/newcampaignform" element={<PrivateRoute element={<NewCampaigns />} permission="IOT_Campaign_Insert" />} />
								<Route path="campaigns/editcampaign/:editionId" element={<PrivateRoute element={<EditCampaign />} permission="IOT_Campaign_Insert" />} />
								<Route path="campaigns/detail/:editionId" element={<PrivateRoute element={<CampaignDetail />} permission="IOT_CampaignEdition_Details" />} />
								<Route path="campaigns/addCampaign/:editionId" element={<PrivateRoute element={<AddCampaign />} permission="IOT_CampaignEdition_Insert" />} />
								<Route path="campaigns/giveaways/:editionId" element={<PrivateRoute element={<GiveawayDetail />} permission="Campaigns_Draw" />} />
								{/* RAW DATA */}
								<Route path="rawdata" element={<PrivateRoute element={<RawData />} permission="IOT_RawData_Select" />} />
								{/* CONTRACTS */}
								<Route path="contracts" element={<PrivateRoute element={<Contracts />} permission="Contracts_Side_Menu" />} />
								<Route path="contracts/newcontract" element={<PrivateRoute element={<NewContract />} permission="IOT_Contract_Insert" />} />
								<Route path="contracts/editcontract/:contractId" element={<PrivateRoute element={<EditContract />} permission="IOT_Contract_Update" />} />
								<Route path="contracts/contractDetail/:contractId" element={<PrivateRoute element={<ContractDetails />} permission="Contracts_Side_Menu" />} />
								{/* LOCATIONS */}
								<Route path="location" element={<PrivateRoute element={<Place />} permission="Places_Side_Menu" />} />
								<Route path="location/newlocation" element={<PrivateRoute element={<NewPlace />} permission="IOT_Place_Insert" />} />
								<Route path="location/editlocation/:placeId" element={<PrivateRoute element={<EditPlace />} permission="IOT_Place_Update" />} />
								<Route path="location/locationDetails/:placeId" element={<PrivateRoute element={<LocationDetailsPage />} permission="Places_Side_Menu" />} />
								{/* DEVICES */}
								<Route path="device" element={<PrivateRoute element={<Device />} permission="Devices_Side_Menu" />} />
								<Route path="device/newdevice" element={<PrivateRoute element={<NewDevice />} permission="IOT_Thing_Insert" />} />
								<Route path="device/rawdata/:deviceId" element={<PrivateRoute element={<RawDataDevice />} permission="Things_RawData" />} />
								<Route path="device/rawdatatable/:externalId" element={<PrivateRoute element={<RawDataTable />} permission="Things_RawData_Table" />} />
								<Route path="device/editdevice/:deviceId/:pageType" element={<PrivateRoute element={<EditDevice />} permission="IOT_Thing_Update" />} />
								{/* STRUCTURES */}
								<Route path="structure" element={<PrivateRoute element={<Structure />} permission="Structures_Side_Menu" />} />
								<Route path="structure/newstructure" element={<PrivateRoute element={<NewStructure />} permission="IOT_Structure_Insert" />} />
								<Route path="structure/editStructure/:structureId" element={<PrivateRoute element={<EditStructure />} permission="IOT_Structure_Update" />} />
								{/* IOT SETTINGS */}
								<Route path="iotcontrol/iotsettings" element={<PrivateRoute element={<IotSettings />} permission="IotConfig_Side_Menu" />} />
								<Route path="iotcontrol/iotsettings/edit" element={<PrivateRoute element={<IotSettingsEdit />} permission="IotConfig_Side_Menu" />} />
								{/* FIRMWARE */}
								<Route path="iotcontrol/firmware" element={<PrivateRoute element={<IotFirmware />} permission="Firmware_Side_Menu" />} />
								<Route path="iotcontrol/firmware/newfirmware" element={<PrivateRoute element={<IotNewFirmware />} permission="Firmware_Side_Menu" />} />
								<Route path="iotcontrol/firmware/editfirmware/:firmwareId" element={<PrivateRoute element={<IotEditFirmware />} permission="Firmware_Side_Menu" />} />
								{/* DEVICE IMPORT */}
								<Route path="iotcontrol/deviceimport" element={<PrivateRoute element={<DeviceImport />} permission="ImportDevices_Side_Menu" />} />
								{/* DEVICE GROUPS */}
								<Route path="iotcontrol/devicegroup" element={<PrivateRoute element={<DeviceGroup />} permission="DeviceGroups_Side_Menu" />} />
								<Route path="iotcontrol/devicegroup/newdevicegroup" element={<PrivateRoute element={<NewDeviceGroup />} permission="DeviceGroups_Side_Menu" />} />
								<Route path="iotcontrol/devicegroup/editdevicegroup/:deviceGroupId" element={<PrivateRoute element={<EditDeviceGroup />} permission="DeviceGroups_Side_Menu" />} />
								{/* DEVICE TYPES */}
								<Route path="iotcontrol/devicetype" element={<PrivateRoute element={<DeviceType />} permission="DeviceTypes_Side_Menu" />} />
								<Route path="iotcontrol/devicetype/newdevicetype" element={<PrivateRoute element={<NewDeviceType />} permission="DeviceTypes_Side_Menu" />} />
								<Route path="iotcontrol/devicetype/editdevicetype/:deviceTypeId" element={<PrivateRoute element={<EditDeviceType />} permission="DeviceTypes_Side_Menu" />} />
								{/* DEVICE MODEL */}
								<Route path="iotcontrol/devicemodel" element={<PrivateRoute element={<DeviceModel />} permission="DeviceModels_Side_Menu" />} />
								<Route path="iotcontrol/deviceModel/newdevicemodel" element={<PrivateRoute element={<NewDeviceModel />} permission="DeviceModels_Side_Menu" />} />
								<Route path="iotcontrol/deviceModel/editdevicemodel/:deviceModelId" element={<PrivateRoute element={<EditDeviceModel />} permission="DeviceModels_Side_Menu" />} />
								{/* ENTITIES */}
								<Route path="usersmanagement/entity" element={<PrivateRoute element={<UsersEntity />} permission="Entities_Side_Menu" />} />
								<Route path="usersmanagement/entity/newentity" element={<PrivateRoute element={<UsersNewEntity />} permission="COM_Entity_Insert" />} />
								<Route path="usersmanagement/entity/editentity/:entityId" element={<PrivateRoute element={<UsersEditEntity />} permission="COM_Entity_Update" />} />
								<Route path="usersmanagement/entity/details/:entityId" element={<PrivateRoute element={<UsersEntityDetails />} permission="Entities_Side_Menu" />} />
								{/* USERS */}
								<Route path="usersmanagement/users" element={<PrivateRoute element={<UsersManagementUsers />} permission="Users_Side_Menu" />} />
								<Route path="usersmanagement/users/inviteusers" element={<PrivateRoute element={<UsersManagementInviteUsers />} permission="COM_User_MayInviteEntityUsers" />} />
								<Route path="usersmanagement/users/details/:userId" element={<PrivateRoute element={<UsersManagementUsersDetails />} permission="Users_Side_Menu" />} />
								{/* EMPLOYEES */}
								<Route path="usersmanagement/employees" element={<PrivateRoute element={<UsersManagementEmployees />} permission="InternalUsers_Side_Menu" />} />
								<Route path="usersmanagement/employees/details/:userId" element={<PrivateRoute element={<UsersManagementEmployeesDetails />} permission="InternalUsers_Side_Menu" />} />
								<Route path="usersmanagement/employees/inviteEmployee" element={<PrivateRoute element={<UsersManagementInviteEmployee />} permission="COM_User_MayAddInternalUsers" />} />
								{/* DATA EXPORT */}
								<Route path="dataexport" element={<PrivateRoute element={<DataExport />} permission="DataExport_Side_Menu" />} />
								{/* DEPOSITS */}
								<Route path="deposits" element={<PrivateRoute element={<Deposits />} permission="Deposits_Side_Menu" />} />
								<Route path="deposits/details/:placeId" element={<PrivateRoute element={<DepositsDetail />} permission="Deposits_Side_Menu"/>} />
								{/* ================== REPORTS - TODO !!! =============== */}
								{/* <Route path="report" element={<PrivateRoute element={<Report />} permission="IOT_Dashboard_Report_View"/>} /> */}
								{/* NOTIFICATIONS */}
								<Route path="notifications" element={<PrivateRoute element={<Notifications />} permission="IOT_Dashboard_Report_View" />} />
								{/* ======== NEW ROUTES - 04-11-2022 ======== */}
								{/* VEHICLES */}
								<Route path="giro/vehicles" element={<PrivateRoute element={<Vehicles />} permission="Vehicles_Side_Menu" />} />
								<Route path="giro/vehicles/newvehicle" element={<PrivateRoute element={<NewVehicle />} permission="REC_Vehicle_Insert" />} />
								<Route path="giro/vehicles/editvehicle/:vehicleId" element={<PrivateRoute element={<EditVehicle />} permission="REC_Vehicle_Update" />} />
								<Route path="giro/vehicles/vehicledetails/:vehicleId" element={<PrivateRoute element={<VehicleDetails />} permission="Vehicles_Side_Menu" />} />
								{/* COLLECTION POINTS */}
								<Route path="giro/collectionPoint" element={<PrivateRoute element={<CollectionPoints />} permission="CollectionPoints_Side_Menu" />} />
								<Route path="giro/collectionPoint/editcollectionpoint/:collectionPointId" element={<PrivateRoute element={<EditCollectionPoints />} permission="CollectionPoints_Side_Menu" />} />
								{/* COLLECTION PLACES */}
								<Route path="giro/collectionPlaces" element={<PrivateRoute element={<CollectionPlaces />} permission="CollectionPlaces_Side_Menu" />} />
								<Route path="giro/collectionPlaces/newCollectionPlace" element={<PrivateRoute element={<NewCollectionPlaces />} permission="REC_CollectionPlace_Insert" />} />
								<Route path="giro/collectionPlace/editCollectionPlace/:collectionPlaceId" element={<PrivateRoute element={<EditCollectionPlaces />} permission="REC_CollectionPlace_Update" />} />
								<Route path="giro/collectionPlace/collectionPlaceDetail/:collectionPlaceId" element={<PrivateRoute element={<CollectionPlaceDetail />} permission="CollectionPlaces_Side_Menu" />} />
								{/* WAREHOUSES */}
								<Route path="giro/warehouses" element={<PrivateRoute element={<Warehouses />} permission="Warehouses_Side_Menu" />} />
								<Route path="giro/warehouses/warehousedetails/:warehouseId" element={<PrivateRoute element={<WarehouseDetails />} permission="Warehouses_Side_Menu" />} />
								<Route path="giro/warehouses/newwarehouse" element={<PrivateRoute element={<NewWarehouse />} permission="REC_Warehouse_Insert" />} />
								<Route path="giro/warehouses/editwarehouse/:warehouseId" element={<PrivateRoute element={<EditWarehouse />} permission="REC_Warehouse_Update" />} />
								{/* OPERATIONS */}
								<Route path="giro/operations" element={<PrivateRoute element={<Operations />} permission="Operations_Side_Menu" />} />
								{/* ROUTES */}
								<Route path="giro/routes" element={<PrivateRoute element={<RoutesPage />} permission={"PredefinedRoutes_Side_Menu" && "IOT_Dashboard_Home_View"} />} />
								<Route path="giro/routes/newroute" element={<PrivateRoute element={<NewRoute />} permission="REC_PredefRoutes_Insert" />} />
								<Route path="giro/routes/editroute/:routeId" element={<PrivateRoute element={<EditRoute />} permission="REC_PredefRoutes_Update" />} />
								<Route path="giro/routes/routedetails/:routeId" element={<PrivateRoute element={<RouteDetails />} permission={"PredefinedRoutes_Side_Menu" && "IOT_Dashboard_Home_View"}/>} />
								{/* PLANNED ROUTES */}
								<Route path="giro/plannedroutes" element={<PrivateRoute element={<RoutesPlanned />} permission={"PlannedRoutes_Side_Menu" && "IOT_Dashboard_Home_View"} />} />
								<Route path="giro/plannedroutes/newroute" element={<PrivateRoute element={<NewRoutePlanned />} permission="REC_PlannedRoutes_Insert" />} />
								<Route path="giro/plannedroutes/editroute/:routeId" element={<PrivateRoute element={<EditRoutePlanned />} permission="REC_PlannedRoutes_Update" />} />
								<Route path="giro/plannedroutes/routedetails/:routeId" element={<PrivateRoute element={<RoutePlannedDetails />} permission={"PlannedRoutes_Side_Menu"&& "IOT_Dashboard_Home_View"} />} />
								{/* MY COLLECTIONS */}
								<Route path="giro/mycollections" element={<PrivateRoute element={<MyCollectionsPage />} permission="REC_Planned_CollectionPoints_Select" />} />
								<Route path="giro/mycollections/:plannedRouteId/newcollection" element={<PrivateRoute element={<NewMyCollection />} permission="REC_Planned_CollectionPoints_Select" />} />
								<Route path="giro/mycollections/debug/:plannedRouteId" element={<PrivateRoute element={<DebugCollectors />} permission="REC_Planned_CollectionPoints_Select" />} />
								{/* CLIENTS */}
								<Route path="giro/clients" element={<PrivateRoute element={<Clients />} permission="CollectionEntities_Side_Menu" />} />
								<Route path="giro/clients/newclient" element={<PrivateRoute element={<NewClient />} permission="REC_Client_Insert" />} />
								<Route path="giro/clients/editclient/:clientId" element={<PrivateRoute element={<EditClient />} permission="REC_Client_Update" />} />
								<Route path="giro/clients/clientdetails/:clientId" element={<PrivateRoute element={<ClientDetails />} permission="CollectionEntities_Side_Menu" />} />
								{/* DRIVERS */}
								<Route path="giro/drivers" element={<PrivateRoute element={<Drivers />} permission="Drivers_Side_Menu" />} />
								<Route path="giro/drivers/newDriver" element={<PrivateRoute element={<NewDriver />} permission="REC_Driver_Insert" />} />
								<Route path="giro/drivers/editDriver/:editDriverId" element={<PrivateRoute element={<EditDriver />} permission="REC_Driver_Update" />} />
								<Route path="giro/drivers/driverDetail/:driverDetailId" element={<PrivateRoute element={<DriverDetail />} permission="Drivers_Side_Menu" />} />
								{/* INTERVENTIONS */}
								<Route path="giro/interventions" element={<PrivateRoute element={<Intervations />} permission="Interventions_Side_Menu" />} />
								{/* WASTE */}
								<Route path="giro/waste" element={<PrivateRoute element={<Waste />} permission="Waste_Side_Menu" />} />
								{/* EGARS */}
								<Route path="giro/egars" element={<PrivateRoute element={<Egars />} permission={"Egars_Side_Menu" && "IOT_Dashboard_Home_View"} />} />
								<Route path="giro/egars/egardetails/:egarId/:navigateto/:type" element={<PrivateRoute element={<EgarDetails />} permission={"Egars_Side_Menu" && "IOT_Dashboard_Home_View"} />} />
								{/* COLLECTION POINT CONTRACT */}
								<Route path="giro/collectionspointcontract" element={<PrivateRoute element={<CollectionPointContract />} permission="CollectionContracts_Side_Menu" />} />
								<Route path="giro/collectionscontract/newcollectioncontracts" element={<PrivateRoute element={<NewCollectionPointContract />} permission="REC_Contract_Insert" />} />
								<Route path="giro/collectionsContract/editCollectionContracts/:collectionContractId" element={<PrivateRoute element={<EditCollectionContract />} permission="REC_Contract_Update" />} />
								<Route path="giro/collectionsContract/collectionContractsDetails/:collectionContractId" element={<PrivateRoute element={<CollectionContractDetail />} permission="CollectionContracts_Side_Menu" />} />
								{/* COLLECTION HISTORY */}
								<Route path="giro/collectionshistory" element={<PrivateRoute element={<CollectionHistory />} permission={"EgarsHistory_Side_Menu"&& "IOT_Dashboard_Home_View"} />} />
								<Route path="giro/collectionshistory/collectionhistorydetails/:collectionHistoryId" element={<PrivateRoute element={<CollectionHistoryDetail />} permission={"EgarsHistory_Side_Menu" && "IOT_Dashboard_Home_View"} />} />
								{/* Oil Collections */}
								<Route path="giro/oilCollections/:oilContainer/:collectiontype" element={<PrivateRoute element={<OilCollection />} permission={"EgarsHistory_Side_Menu"&& "IOT_Dashboard_Home_View"} />} />
								{/* Cf- To Go */}
								{/* Account Verification */}
								<Route path="to_go/accountVerification" element={<PrivateRoute element={<Tg_AccountVerification />} permission={"Flow_AccountVerify_Side_Menu"} />} />
								<Route path="to_go/accountVerificationDatails/:tgUser" element={<PrivateRoute element={<Tg_AccountVerificationDetails />} permission={"Flow_AccountVerify_Side_Menu"} />} />
								{/* Register */}
								<Route path="to_go/register" element={<PrivateRoute element={<Tg_Register />} permission={"Flow_Register_Side_Menu"} />} />
								<Route path="to_go/register/:tgUser" element={<PrivateRoute element={<Tg_RegisterDetails />} permission={"Flow_Register_Side_Menu"} />} />
								{/* Client Verification */}
								<Route path="to_go/clientsVerification" element={<PrivateRoute element={<Tg_ClientsVerification />} permission={"Flow_Clients_Side_Menu"} />} />
								<Route path="to_go/clientsDetails/:tgClient" element={<PrivateRoute element={<Tg_ClientDetails />} permission={"Flow_Clients_Side_Menu"} />} />
								{/* Delivery */}
								<Route path="to_go/delivery" element={<PrivateRoute element={<Tg_Delivery />} permission={"Flow_Deliveries_Side_Menu"} />} />
								<Route path="to_go/deliveryDetail/:tgDelivery" element={<PrivateRoute element={<Tg_DeliveryDetail />} permission={"Flow_Deliveries_Side_Menu"} />} />
								{/* Collectors TG */}
								<Route path="to_go/collectors" element={<PrivateRoute element={<Tg_Collector />} permission={"Flow_Drivers_Side_Menu"} />} />
								<Route path="to_go/collectorDetail/:tgCollector" element={<PrivateRoute element={<Tg_CollectorDetail />} permission={"Flow_Drivers_Side_Menu"} />} />
								{/* weekley price TG */}
								<Route path="to_go/weeklyPrice" element={<PrivateRoute element={<WeeklyPrice />} permission={"Flow_WeeklyPrices_Side_Menu"} />} />
								{/* end Cf- To Go */}
								{/* Contacts */}
								<Route path="contacts" element={<PrivateRoute element={<Contacts />} permission={"ContactsDashboard_Side_Menu"} />} />
								{/* <Route path="contacts/newRegister/:useCredits" element={<PrivateRoute element={<ContactsRegister />} permission={"COT_Contact_Insert"} />} /> */}
								<Route path="contacts/details/:newRegister/:collPoint" element={<PrivateRoute element={<ContactClientDetails />} permission={"NextContact_Side_Menu"} />} />
								<Route path="contacts/orders" element={<PrivateRoute element={<Orders />} permission={"StockConfirmation_Side_Menu"} />} />
								<Route path="contacts/workload" element={<PrivateRoute element={<Workload />} permission={"Workload_Side_Menu"} />} />
								{/* Reports */}
								<Route path="reports" element={<PrivateRoute element={<Reports />} permission={"Reports_Side_Menu"} />} />
								{/* Params */}
								<Route path="params" element={<PrivateRoute element={<Params />} permission={"Params_Side_Menu"} />} />
							</Route>
							<Route path="*" element={<NoAccess />} />
						</Routes>
					</CustomRouter>
				</Suspense>
			</SidebarContext.Provider>
		</LoadingContext.Provider>
	);
}

export default App;
